(function($) {
    /**
     * Enable date-autocompletion for all input elements that have the data-attribute
     * 'date-autocomplete' on it. De value of the data attribute determines to set the
     * day and month to either the start or the end of the month.
     *
     * Possible data attribute values:
     * up - select the highest available month/day
     * down - select the lowest available month/day
     *
     * Example:
     * <input type="text" data-date-autocomplete="up">
     *
     * In this example, when '2008' is entered, it will be returned as 31-12-2008.
     *
     * Example 2:
     * <input type="text" data-date-autocomplete="down">
     *
     * When 02-2012 is entered, 01-02-2012 will be returned
     *
     * @returns {*}
     */
    $.fn.dateAutoCompletion = function(options) {

        // Set options
        var settings = $.extend({
            format: 'DD-MM-YYYY'
        }, options);

        // Loop through selected elements and apply correct format
        return this.each(function() {
            $(this).on('blur', function() {
                var type = $(this).data('dateAutocomplete');
                this.value = complete(this.value, type).format(settings.format);
            });
        });

        /**
         * Check any given (part of) date, and parse it to something useable for autocompletion
         *
         * @param value
         * @param type
         * @returns {*}
         */
        function complete(value, type) {
            if (typeof type === 'undefined') {
                type = 'down';
            }

            // only year is given, so default to the last month, last day of given year
            var time = moment(value, ['YYYY'], true);
            if (time.isValid()) {
                if (type === 'down') {
                    return time.startOf('year').startOf('month');
                } else if (type === 'up') {
                    return time.endOf('year').endOf('month');
                }
            }

            // date with a supplied month, no day given, so default to last day of the month
            var time = moment(value, ['MM-YYYY', 'MM/YYYY', 'MMYYYY'], true);
            if (time.isValid()) {
                if (type === 'down') {
                    return time.startOf('month');
                } else if (type === 'up') {
                    return time.endOf('month');
                }
            }

            // date with a supplied month, no day given, so default to last day of the month
            var time = moment(value, ['YYYY-MM', 'YYYY/MM'], true);
            if (time.isValid()) {
                if (type === 'down') {
                    return time.startOf('month');
                } else if (type === 'up') {
                    return time.endOf('month');
                }
            }

            // Given value didn't match the above formats, so just return the given value
            return value;
        }

    }
}(jQuery));
