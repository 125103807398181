(function (t) {
// nl
t.add("scan.confirmation.remove", "Weet u zeker dat u dit bestand wilt verwijderen?", "javascripts", "nl");
t.add("cart.action.destroyOrder", "Vernietig-opdracht voor %name%", "javascripts", "nl");
t.add("cart.action.lendOrder", "Bestel-opdracht voor %name%", "javascripts", "nl");
t.add("cart.action.scanOrder", "Scan-opdracht voor %name%", "javascripts", "nl");
t.add("cart.customer", "Klant: %name%", "javascripts", "nl");
t.add("cart.buttons.send", "Verzend opdracht", "javascripts", "nl");
t.add("cart.confirmation.destroyOrder", "Weet u zeker dat u gekozen stukken definitief wilt vernietigen?", "javascripts", "nl");
t.add("cart.confirmation.remove", "Weet u zeker dat u dit stuk uit de winkelwagen wilt verwijderen?", "javascripts", "nl");
t.add("cart.confirmation.scanOrder", "Weet u zeker dat u de bestelling wilt bevestigen?", "javascripts", "nl");
t.add("order.confirmation.scanOrder", "Er wordt nu een lijst opgesteld, waarmee de stukken in het magazijn teruggeplaatst kunnen worden. Heeft u alle stukken ingescand?", "javascripts", "nl");
t.add("form.putAway.location_or_container_error", "Kies of een locatie, of een doos. Niet beide.", "javascripts", "nl");
t.add("grid.cart.common.id", "ID", "javascripts", "nl");
t.add("grid.cart.common.description", "Omschrijving", "javascripts", "nl");
t.add("grid.cart.common.type", "Type", "javascripts", "nl");
})(Translator);
